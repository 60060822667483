body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: #121212;
  color: #e0e0e0;
}

.App {
  display: flex;
  flex-direction: column;
}

.layout {
  display: flex;
}

.left-panel {
  background: #1f1f1f;
  color: #e0e0e0;
  width: 25%; /* Reduced width for better balance */
  padding: 20px;
  text-align: center;
  position: fixed; /* Ensures it remains fixed */
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  overflow: hidden; /* Prevent scrolling */
}

.left-panel img {
  margin-top: 20px; /* Add padding before the image */
  width: 200px; /* Slightly larger for better prominence */
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px; /* Increased padding */
  transition: transform 0.3s ease, border 0.3s ease;
  border: 3px solid transparent;
}

.left-panel img:hover {
  transform: scale(1.1);
  border: 3px solid #c4a76d; /* Changed to golden color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.left-panel h3 {
  margin: 15px 0;
  font-size: 1.8rem; /* Larger font size */
  color: #c4a76d; /* Changed to golden color */
}

.left-panel p {
  margin: 10px 0;
  font-size: 0.9rem; /* Standardized font size */
  line-height: 1.5;
}

.left-panel nav {
  position: relative;
}

.left-panel nav a {
  display: block;
  padding: 12px;
  margin: 5px 0;
  color: #e0e0e0;
  text-decoration: none;
  transition: color 0.3s;
  position: relative;
  font-size: 1.1rem; /* Increased font size */
  margin: 7px 0;
}

.left-panel nav a:hover,
.left-panel nav a.active {
  color: #c4a76d; /* Changed to golden color */
}

.left-panel nav a.active::before,
.left-panel nav a:hover::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 20px;
  background-color: #c4a76d; /* Vertical indicator */
}

.contact-info {
  margin-top: 20px;
}

.contact-info p {
  margin: 5px 0;
}

.contact-info a {
  margin: 0 10px;
  color: #e0e0e0;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.contact-info a:hover {
  color: #c4a76d; /* Changed to golden color */
}

.sidebar-icons a {
  margin: 0 10px;
  color: #e0e0e0;
  font-size: 1.8rem;
  transition: color 0.3s;
}

.sidebar-icons a:hover {
  color: #c4a76d; /* Changed to golden color */
}

.main {
  flex: 1;
  padding: 20px;
  margin-left: 320px; /* Adjusted for new left panel width */
  overflow-y: auto;
}

.card {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.card h3 {
  font-size: 1.5rem;
  color: #c4a76d; /* Changed to golden color for category titles */
}

.card p {
  margin: 5px 0;
}

.footer {
  text-align: center;
  font-size: 0.9rem;
  padding-top: 20px;
  padding-bottom: 10px; /* Optional for bottom spacing */
  margin-top: 20px;
}

.language-bar {
  background-color: #333;
  border-radius: 5px;
  height: 10px;
  margin: 10px 0;
  position: relative;
  width: 100%;
}

.language-bar .fill {
  background-color: #c4a76d; /* Golden color */
  border-radius: 5px;
  height: 100%;
  position: absolute;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it sits above other elements */
}

.modal-content {
  position: relative;
  width: 80vw;
  height: auto;
  max-width: 600px;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px; /* Adds rounded corners */
  overflow: hidden; /* Ensures rounded corners apply */
  background-color: #121212; /* Background for visibility */
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 12px; /* Ensures image respects rounded corners */
  transition: border 0.3s ease; /* Smooth transition for hover effect */
}

.card {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background: linear-gradient(
    to bottom right,
    rgba(42, 42, 42, 0.9),
    rgba(34, 34, 34, 0.6)
  ); /* Adjust the colors and transparency levels as needed */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #e0e0e0; /* Ensure text is readable over the gradient */
}

/* Add hover effect for golden border */

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
  background: transparent;
  border: none;
}

.divider {
  border: 0;
  height: 1px;
  background: #c4a76d; /* Golden color */
  margin: 10px 0;
  opacity: 0.5;
}

/* Language button styling */

.floating-language-switcher {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000; /* Ensure it stays above other content */
  display: flex;
  gap: 8px; /* Reduced gap for a more compact look */
}

.language-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 3px; /* Reduced padding */
  transition: transform 0.2s, color 0.2s;
}

.language-button .language-icon {
  font-size: 1.2rem; /* Reduced font size for smaller icons */
  color: #e0e0e0; /* Default icon color */
}

.language-button:hover .language-icon,
.language-button.active .language-icon {
  color: #c4a76d; /* Golden color for hover or active state */
}

.language-button.active {
  transform: scale(1.05); /* Slightly smaller scale on active state */
}

/* Workplace card styling */

.workplace-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px;
  background: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.2s ease;
  text-decoration: none; /* Remove link underline */
  color: inherit; /* Inherit text color */
  cursor: pointer;
}

.workplace-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #333; /* Slightly darker on hover */
}

.workplace-header {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap if necessary */
  justify-content: flex-start; /* Align items to the left */
  align-items: center;
  gap: 5px; /* Add space between elements */
  margin-bottom: 10px;
}

.workplace-logo {
  width: 50px; /* Maintain a reasonable size for the logo */
  height: auto;
  margin-right: 10px;
  border-radius: 5px;
  flex-shrink: 0; /* Prevent the logo from shrinking */
}

.workplace-title-container {
  display: flex;
  flex-direction: column; /* Stack title and position */
  flex-grow: 1; /* Allow the container to grow */
  min-width: 150px; /* Ensure sufficient space for text */
}

.position {
  font-weight: bold;
  font-size: 1rem;
  color: #c4a76d; /* Matches theme */
  text-align: right; /* Align to the right for better separation */
  flex-shrink: 0; /* Prevent shrinking */
}

.workplace-details {
  display: flex;
  flex-wrap: wrap; /* Allow details to wrap */
  justify-content: space-between; /* Add space between details */
  gap: 5px; /* Add a small gap for clarity */
  font-size: 0.9rem;
  color: #e0e0e0;
}

.time-period,
.working-hours {
  margin: 0; /* Remove default margins */
  flex: 1 1 auto; /* Allow items to share space equally */
  text-align: left; /* Ensure consistent alignment */
}

.education-card {
  margin-bottom: 0px;
}

.workplace-logo {
  width: 40px; /* Adjust as needed */
  height: auto;
  margin-right: 5px; /* Reduce spacing to bring text closer */
  vertical-align: middle;
  border-radius: 5
}

.position {
  font-weight: bold;
  font-size: 1rem;
  color: #c4a76d; /* Matches theme */
}


.workplace-title-container {
  display: flex;
  align-items: center; /* Centers logo and name vertically */
}

.position {
  margin-left: auto; /* Pushes position text to the far right */
}

.profile-video-large {
  width: 150px; /* Smaller size */
  height: 150px;
  border-radius: 50%; /* Makes it a circle */
  object-fit: cover;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 3px solid transparent;
  transition: transform 0.3s ease, border 0.3s ease;
  clip-path: circle(50%); /* Ensures content is clipped within a circle */
}

.profile-video-large:hover {
  transform: scale(1.1);
  border: 3px solid #c4a76d; /* Golden color border on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* General mobile styling */
@media (max-width: 768px) {
  .layout {
    flex-direction: column;
  }

  .left-panel {
    width: 100%;
    height: auto;
    position: relative;
    padding: 10px;
    margin-bottom: 20px;
    overflow: visible;
  }

  .main {
    margin-left: 0;
    padding: 10px;
  }

  .left-panel img {
    width: 150px;
    height: 150px;
    margin: 0 auto 10px;
  }

  .left-panel h3 {
    font-size: 1.5rem;
  }

  .left-panel nav a {
    padding: 10px;
    font-size: 1rem;
  }

  .sidebar-icons a {
    font-size: 1.5rem;
  }
  
  .modal-content {
    width: 90vw;
    max-width: 500px;
  }

  .floating-language-switcher {
    position: relative; /* Make relative for mobile to allow scrolling */
    top: auto;
    left: auto;  }

  .left-panel {
    overflow: auto; /* Allow scrolling */
  }

    .workplace-header {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the left */
    margin-bottom: 10px; /* Add space below the header */
  }

  .workplace-logo {
    margin-right: 0; /* Remove extra spacing */
    margin-bottom: 10px; /* Add space below the logo */
  }

  .position {
    font-size: 0.9rem; /* Reduce font size */
    margin-left: 0; /* Remove auto margin */
    margin-top: 5px; /* Add spacing above the position */
  }

  .workplace-details {
    flex-direction: column; /* Stack details vertically */
    align-items: flex-start; /* Align items to the left */
    gap: 5px; /* Add spacing between items */
  }

  .time-period,
  .working-hours {
    font-size: 0.85rem; /* Adjust font size for smaller screens */
  }
}

